<template>
  <div>
    <p class="text-2xl mb-6">Liste des relations</p>
    <v-card class="mb-6 pa-2" outlined>
      <filters></filters>
    </v-card>

    <v-data-table
      dense
      :page="options.page"
      :pageCount="numberOfPages"
      :headers="headers"
      :items="items"
      disable-sort
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
      }"
      :items-per-page="options.itemsPerPage"
    >
      <template v-slot:item.active="{ item }">
        <v-icon color="primary" v-if="item.active">
          {{ icons.mdiCheck }}
        </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <div>
          <v-btn
            :to="`/admin/partner/relation/edit/${item.id}`"
            class="mx-2"
            elevation="0"
            fab
            small
          >
            <v-icon color="primary">
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            elevation="0"
            fab
            small
            @click.native="
              dialogModel.visible = !dialogModel.visible;
              dialogModel.id = item.id;
              dialogModel.partnerView = item.partner_view_name;
              dialogModel.brand = item.brand_name;
            "
          >
            <v-icon color="secondary">
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogModel.visible" max-width="800">
      <del-confirmation
        :dialogOpen="dialogModel.visible"
        :id="dialogModel.id"
        :partnerView="dialogModel.partnerView"
        :brand="dialogModel.brand"
        @openDialog="openDialog"
        @removedItem="refreshWindow"
      ></del-confirmation>
    </v-dialog>
  </div>
</template>

<script>
import { mdiCheck, mdiPencil, mdiTrashCan } from "@mdi/js";
import axios from "@axios";

import Filters from "@/components/admin/partner/relations/filters/Filters";
import DelConfirmation from "@/components/admin/partner/relations/modals/DelConfirmation";

export default {
  name: "RelationList",
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    DelConfirmation,
    Filters,
  },
  data() {
    return {
      forceCall: false, // onLoad once everything is loaded, or when options change
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 100,
      },
      total: 0,
      numberOfPages: 0,
      items: [],
      headers: [
        { text: "Vue partenaire", value: "partner_view_name" },
        { text: "Marque", value: "brand_name" },
        { text: "Entité de facturation", value: "billing_name" },
        { text: "Entité d'encaissement", value: "cashing_name" },
        { text: "Actions", value: "actions" },
      ],
      icons: {
        mdiCheck,
        mdiPencil,
        mdiTrashCan,
      },
      dialogModel: {
        visible: false,
        id: 0,
        partnerView: "",
        brand: "",
      },
    };
  },
  created() {
    this.forceCall = true;
  },
  methods: {
    formatResult(data) {
      return {
        ...data,
        brand_name:
          this.brandList.find((brand) => brand.id === data.brand_id ?? 0)
            ?.name ?? "",
        partner_view_name:
          this.partnerViewList.find(
            (partnerView) => partnerView.id === data.partner_view_id ?? 0
          )?.label ?? "",
        billing_name:
          this.billingList.find(
            (billing) => billing.id === data.billing_id ?? 0
          )?.name ?? "",
        cashing_name:
          this.cashingList.find(
            (cashing) => cashing.id === data.cashing_id ?? 0
          )?.name ?? "",
      };
    },
    async getData() {
      if (!this.canGetData) {
        this.forceCall = false;

        return;
      }
      if (!this.forceCall && (!this.applyFilters || this.loading)) {
        return;
      }

      this.loading = true;
      const { page, itemsPerPage } = this.options;
      const queryParams = {
        ...(this.partnerViews.length > 0
          ? { partner_view_ids: this.partnerViews.join(",") }
          : {}),
        ...(this.brands.length > 0 ? { brand_ids: this.brands.join(",") } : {}),
        ...(this.billings.length > 0
          ? { billing_ids: this.billings.join(",") }
          : {}),
        ...(this.cashings.length > 0
          ? { cashing_ids: this.cashings.join(",") }
          : {}),
        page,
        limit: itemsPerPage,
      };

      const { data } = await axios.get("/partners/relations", {
        params: queryParams,
      });
      this.items = data.items.map((item) => this.formatResult(item));
      this.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;

      this.loading = false;
      this.forceCall = false;

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
    openDialog(value) {
      this.dialogModel.visible = value;
    },
    refreshWindow() {
      this.$router.go();
    },
  },
  computed: {
    canGetData() {
      return (
        this.billingList.length > 0 &&
        this.cashingList.length > 0 &&
        this.brandList.length > 0 &&
        this.partnerViewList.length > 0
      );
    },
    billingList() {
      return this.$store.getters["admin/getBillingList"];
    },
    cashingList() {
      return this.$store.getters["admin/getCashingList"];
    },
    brandList() {
      return this.$store.getters["common/getSiteBrandList"];
    },
    partnerViewList() {
      return this.$store.getters["common/getPartnerViewList"];
    },
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    billings() {
      return this.$store.getters["admin/getBillings"];
    },
    cashings() {
      return this.$store.getters["admin/getCashings"];
    },
    brands() {
      return this.$store.getters["admin/getBrands"];
    },
    partnerViews() {
      return this.$store.getters["admin/getPartnerViews"];
    },
  },
  watch: {
    applyFilters() {
      this.getData();
    },
    billingList() {
      this.getData();
    },
    cashingList() {
      this.getData();
    },
    brandList() {
      this.getData();
    },
    partnerViewList() {
      this.getData();
    },
    options() {
      this.forceCall = true;
      this.getData();
    },
  },
};
</script>

<style scoped></style>
